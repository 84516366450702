<template>
  <div class="couses-add">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
      <header-pages
        title="تعديل الدوره"
        :showButton="false"
        link="/edit-courses"
      />
    </div>
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الدورة</label>
          <div class="input">
            <input
              v-model="Courses.name"
              placeholder="أدخل أسم الدورة"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> السنوات الدراسيه </label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            placeholder="السنوات الدراسيه"
            select-label=""
            deselect-label=""
            v-model="Courses.year_id"
            :options="yearsOptions"
            track-by="id"
            label="title"
            :multiple="false"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> الفصول الدراسيه </label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            placeholder="الفصول الدراسيه"
            select-label=""
            deselect-label=""
            v-model="Courses.season_id"
            :options="seasonOptions"
            track-by="id"
            label="title"
            :multiple="false"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> المناهج </label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            placeholder="المناهج"
            deselect-label=""
            v-model="Courses.curriculum_id"
            :options="curriculaOptions"
            track-by="id"
            label="title"
            :multiple="false"
          >
          </multiselect>
          <!-- <div style="display: flex">
            <input
              type="checkbox"
              id="allCurriculum"
              :checked="Courses.all_curriculum === 1"
              @change="changeAllCurriculum"
            />
            <label for="allCurriculum"> المنهج كامل (جميع المراحل)</label>
          </div> -->
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> المراحل </label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            placeholder="المراحل"
            v-model="Courses.stage_ids"
            :options="stageOptions"
            v-if="showStages"
            :disabled="!showStages"
            :close-on-select="false"
            track-by="id"
            label="title"
            @change="Disapility"
            :multiple="true"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Disapilty"> الاعاقات </label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            placeholder="الاعاقات"
            deselect-label=""
            v-model="Courses.disability_ids"
            :options="DisapilityOptions"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import { useCoursesEditStore } from "@/stores/courses/CoursesEditStore";
import Multiselect from "vue-multiselect";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  data() {
    return {
      store: useCoursesEditStore(),
      showStages: true,
      Courses: {
        name: "",
        year_id: null,
        stage_ids: [],
        curriculum_id: null,
        disability_ids: [],
        season_id: [],
        all_curriculum: null,
      },
      yearsOptions: [],
      stageOptions: [],
      curriculaOptions: [],
      DisapilityOptions: [],
      seasonOptions: [],
    };
  },
  components: {
    Multiselect,
    HeaderPages,
  },
  methods: {
    changeAllCurriculum(event) {
      this.Courses.all_curriculum = event.target.checked ? 1 : 0;
    },
    async years() {
      await this.store.fetchYears();
      this.yearsOptions = this.store.years || [];
    },
    async Curriculums() {
      await this.store.fetchCurricula();
      this.curriculaOptions = this.store.Curricula || [];
    },
    async Disapility() {
      if (!this.Courses.stage_ids || this.Courses.stage_ids.length === 0)
        return; // التأكد من وجود المراحل

      // استخراج المعرفات لكل مرحلة
      const selectedStageIds = this.Courses.stage_ids.map((stage) => stage.id);

      console.log(selectedStageIds, "selected stage ids");

      // استدعاء دالة fetchDisabilities مع IDs المراحل
      await this.store.fetchDisabilities(selectedStageIds);

      // تخزين الإعاقات التي تم جلبها
      this.DisapilityOptions = this.store.disabilities || [];
    },
    async Seasons() {
      await this.store.fetchSeasons();
      this.seasonOptions = this.store.seasonStudies || [];
    },
    async getcurricula() {
      await this.store.fetchCurricula();
      this.curriculaOptions = this.store.Curricula;
    },
    async getstages() {
      try {
        await this.store.fetchStages(this.Courses.curriculum_id.id);
        this.stageOptions = this.store.Stages;
        console.log(this.stageOptions);
      } catch (error) {
        console.error("خطأ في جلب المراحل:", error);
      }
    },

    // changeAllCurriculum() {
    //   this.showStages = !this.showStages;
    // },
    async CoursesDetails() {
      await this.store.fetchCoursesDetails(this.$route.params.id);
      this.Courses.name = this.store.coursesDetails.name;
      this.Courses.year_id = this.store.coursesDetails.year;
      this.Courses.curriculum_id = this.store.coursesDetails.curriculum;
      this.Courses.disability_ids = this.store.coursesDetails.disability_types;
      this.Courses.season_id = this.store.coursesDetails.season;
      this.Courses.stage_ids = this.store.coursesDetails.stages;
      this.Courses.all_curriculum = this.store.coursesDetails.all_curriculum;
    },
    submitForm() {
      // تأكد من أن جميع الحقول تم تعيينها بشكل صحيح
      this.Courses.year_id = this.Courses.year_id?.id || null;
      this.Courses.disability_ids = this.Courses.disability_ids.map(
        (item) => item.id
      );
      this.Courses.season_id = this.Courses.season_id?.id || null;
      this.Courses.curriculum_id = this.Courses.curriculum_id?.id || null;
      this.Courses.stage_ids = this.Courses.stage_ids.map((item) => item.id);
      this.Courses.all_curriculum = this.showStages ? 0 : 1;
      this.store.updateCourse(this.$route.params.id, this.Courses);
    },
  },
  async mounted() {
    this.years();
    this.Curriculums();
    this.Disapility();
    this.Seasons();
    this.getstages();
    this.CoursesDetails();
    await this.store.fetchCoursesDetails(this.$route.params.id);
  },
  watch: {
    "Courses.curriculum_id": function (newVal) {
      if (newVal) {
        this.getstages();
      }
    },
    "Courses.stage_ids": function (newVal) {
      if (newVal) {
        this.Disapility();
      }
    },
  },
};
</script>
<style scoped>
.all-btn {
  margin-top: 1rem;
}
</style>
  