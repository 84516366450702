import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useCoursesEditStore = defineStore("coursesEdit", {
  state: () => ({
    coursesDetails: {},
    seasonStudies: [],
    curriculums: [],
    Stages: [],
    disabilities: [],
    curriculum_id: [],
    stage_ids: [],
  }),
  actions: {
    async fetchCoursesDetails(id) {
      try {
        const response = await axios.post("fetch_course_details", { id: id });
        if (response.data.status === true) {
          // console.log(response.data.data, "data");
          this.coursesDetails = response.data.data;
        } else {
          console.log("Error fetching courses data");
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchSeasons() {
      try {
        const response = await axios.get("organization_fetch_seasons");
        if (response.data.status) {
          this.seasonStudies = response.data.data;
        } else {
          console.error("Error fetching seasons:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        if (response.data.status) {
          this.curriculums = response.data.data;
        } else {
          console.error("Error fetching curriculums:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchCurricula() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        if (response.data.status == true) {
          this.Curricula = response.data.data;
        } else {
          console.error("Error fetching curricula:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchStages(id) {
      try {
        const response = await axios.post("organization_fetch_stages", {
          curriculum_id: id,
        });
        if (response.data.status == true) {
          this.Stages = response.data.data.data;
          console.log(this.Stages, "stages");

          this.stage_ids = this.Stages.map((stage) => stage.id);
        } else {
          console.error("Error fetching stages:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async fetchDisabilities(id) {
      const response = await axios.post(
        "organization_fetch_disability_by_stage_ids", {
        stage_ids: id
      });
      if (response.data.status == true) {
        this.disabilities = response.data.data;
        this.disability_ids = this.disabilities.map((disability) => disability.id);
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchYears() {
      const response = await axios.get("organization_fetch_years");
      if (response.data.status === true) {
        this.years = response.data.data;
      } else {
        console.log("Error fetching years.");
      }
    },
    async updateCourse(id, updatedData) {
      try {
        // إنشاء كائن FormData
        const formData = new FormData();

        // إضافة القيم إلى FormData
        formData.append("id", id);
        for (const key in updatedData) {
          if (Array.isArray(updatedData[key])) {
            // إذا كانت القيمة مصفوفة
            updatedData[key].forEach((item, index) => {
              if (typeof item === "object" && item !== null) {
                // تحويل الكائن إلى JSON إذا كان عنصر المصفوفة كائنًا
                formData.append(`${key}[${index}]`, JSON.stringify(item));
              } else {
                formData.append(`${key}[${index}]`, item);
              }
            });
          } else if (
            typeof updatedData[key] === "object" &&
            updatedData[key] !== null
          ) {
            // إذا كانت القيمة كائنًا
            formData.append(key, JSON.stringify(updatedData[key]));
          } else {
            // إضافة القيم العادية
            formData.append(key, updatedData[key]);
          }
        }

        console.log("البيانات المرسلة إلى API (FormData):", formData);

        // إرسال البيانات باستخدام axios
        const response = await axios.post("edit_course", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("الاستجابة من API: ", response.data);

        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: response.data.message || "تم تعديل الدورة بنجاح",
          });
          router.push("/courses");
        } else {
          console.error("حدث خطأ أثناء تحديث الدورة:", response.data.message);
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message || "حدث خطأ أثناء تحديث الدورة",
          });
        }
      } catch (error) {
        console.error("فشل الاتصال بـ API:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ أثناء الاتصال بالخادم",
        });
      }
    },
  },
});
