<template>
  <div class="groups">
    <h4 style="font-family: 'bold'">اضافة مجموعة</h4>
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المجموعة</label>
          <div class="input">
            <input
              v-model="title"
              placeholder="أدخل أسم المجموعة"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">تاريخ البدايه</label>
          <div class="input">
            <input v-model="start_date" placeholder="من " type="date" />
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      title: "",
      start_date: "",
      id: this.$route.params.id,
    };
  },
  methods: {
    async submitForm() {
      try {
        let formData = new FormData();
        formData.append("title", this.title);
        formData.append("start_date", this.start_date);
        formData.append("course_id", this.id);

        const res = await axios.post("add_group", formData);

        if (res.data.status == true) {
          this.$router.push(`/groups-index/${this.id}`);
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: res.data.message || "تم إضافة المجموعه بنجاح",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: res.data.message || "حدث خطأ أثناء الحفظ",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "تعذر الاتصال بالسيرفر. يرجى المحاولة لاحقًا.",
        });
        console.error(error);
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
button.save {
  display: block;
  margin-inline-start: auto;
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.7s;
  width: 22%;
  border: 1px solid var(--main);
}
button.save:hover {
  background-color: var(--secondary);
  color: var(--main);
  border: 1px solid var(--secondary);
}
.all-btn {
  margin-top: 1rem;
}
</style>