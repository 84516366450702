<template>
  <div class="show-result">
    <div class="hed">
      <h4 style="font-family: 'bold'; margin-bottom: 1rem">تفاصيل الامتحان</h4>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>نص السؤال</th>
            <th>اجابة الطالب</th>
            <th>نص الاجابه(خاص بالطالب)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="show.length === 0" colspan="5" class="text-center">
              لا توجد بيانات لعرضها
            </td>
          </tr>
          <tr v-for="resualt in show" :key="resualt.id">
            <td>{{ resualt.question_title }}</td>
            <td>
              <i
                v-if="resualt.is_correct === 1"
                class="fa-solid fa-check text-success"
              ></i>
              <i v-else class="fa-solid fa-circle-xmark text-danger"></i>
            </td>
            <td>{{ resualt.answer_title }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  <script>
import axios from "axios";

export default {
  data() {
    return {
      show: [],
    };
  },
  methods: {
    async fetchShowResualtsStore() {
      try {
        const response = await axios.post("fetch_group_exam_user_details ", {
          id: this.$route.params.id,
          user_id: this.$route.params.userId,
        });
        if (response.data.status === true) {
          this.show = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.fetchShowResualtsStore();
  },
};
</script>
