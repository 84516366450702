<template>
  <div class="infoo">
    <h2>المقرأة الالكترونية</h2>
    <div class="im">
      <img src="../../../assets//photos/green squr.png" alt="" />
      <h3>الدروس المباشرة هي حصص لايف مع المعلم الخاص بك</h3>
    </div>

    <div class="im">
      <img src="../../../assets//photos/green squr.png" alt="" />
      <h3>تواصل مباشر مع المعلم حيث يمكنك سؤاله فى اى وقت</h3>
    </div>
    <div class="im">
      <img src="../../../assets//photos/green squr.png" alt="" />
      <h3>دروس معدة خصيصا لك ليستطيع المعلم وولى الامر معرفة مستوي تقدمك</h3>
    </div>
  </div>

  <div class="lessonss">
    <router-link to="">
      <img src="../../../assets//photos/leso.png" alt="" />
    </router-link>
    <router-link to="">
      <img src="../../../assets//photos/aft les.png" alt="" />
    </router-link>
    <router-link to="">
      <img src="../../../assets//photos/endlesoo.png" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
* {
  font-family: "bold"
}
.im {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.infoo {
  background-image: url(../../../assets/photos/baf.png);
  background-repeat: no-repeat;
  margin-top: 8px;
  h2 {
    font-size:30px;
    font-weight: bold;
    margin-bottom: 48px;
    text-align: center;
  }
  h3 {
    font-family: "regular";
    font-size: 20px;
  }
}
.lessonss {
  text-align: center;
  margin-top: 66px;

  img {
    width: 27%;
    margin-left: 60px;
  }
}
// @media screen and (max-width: 425px) {
//   .lessonss img[data-v-f2e6f56c] {
  
//     margin-left: 60px;
//     display: block;
//     width: 91%;
//     margin: auto;
//     margin-top: 21px;
// }
// }

@media screen and (max-width: 768px ){ 
  .lessonss img[data-v-f2e6f56c] {
    width: 32%;
    margin-left: 0;
    margin-top: 20px;
    padding-left: 7px;
}
}
</style>