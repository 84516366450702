<template>
  <div class="coode">
    <div  class="Codepage">
 
    <form @submit.prevent="getCode">
      <div class="content" style="text-align: center;">
        <img src="../../../assets/photos/logo.png" alt="" />

        <h3>أدخل الكود المرسل</h3>
        <div class="input-group mb-3 mt-4">
          <div class="card flex justify-center" style="width: 100%">
            <!-- <InputOtp
            v-model="code"
            type="text"
            class="form-control"
           
          /> -->
          </div>
          <input
            v-model="code"
            type="text"
            class="form-control"
            placeholder="أدخل الكود المرسل"
            maxlength="4"
            required
          />
        </div>
        <button type="submit" class="btn">استرجاع كلمة المرور</button>
      </div>
    </form>
         
  </div>
    <div class="image-container">
      <img
        src="@/assets/photos/reset_pass.png"
        alt="Password Reset"
        class="img-fluid"
      />
    </div>
  </div>
</template>

<script>
import InputOtp from "primevue/inputotp";
import { useAuthStore } from "@/stores/auth/AuthStore";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CodeReset",
  data() {
    return {
      code: "",
      value: null,
    };
  },
  components: {
    InputOtp,
  },
  methods: {
    // newPass() {
    //   router.push("/resetpassword");
    // },
    // validateCode() {
    //   if (this.code.length === 6) {
    //     this.$router.push("/resetpassword");
    //   } else {
    //     alert("Please enter a valid 6-digit code.");
    //   }
    // },
    async getCode() {
      try {
        const email = localStorage.getItem("email");
        const formData = new FormData();
        formData.append("code", this.code);
        formData.append("email", email);

        const response = await axios.post(
          "organization-reset-password-check-code",
          formData
        );

        if (response.data.status === true) {
          if (response.data.data) {
            this.$router.push("/ResetPassword");

            Swal.fire({
              icon: "success",
              title: "تم إرسال الكود بنجاح",
              text: response.data.message || "تم إرسال الكود بنجاح",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: "لم يتم استلام الكود بشكل صحيح.",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "الكود غير صالح",
            text: response.data.message || "يرجى إدخال كود صالح",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "حدث خطأ",
          text: "هناك مشكلة في الاتصال بالخادم.",
        });
      }
    },

    async validateCodes() {
      const authStore = useAuthStore();
      await authStore.validateCode(this.code);
    },
    // remove() {
    //   localStorage.removeItem("email");
    // },
  },
};
</script>


<style scoped lang="scss">
.coode{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, var(--main), var(--primary));
  height: 100vh;
  gap: 320px;

}

.Codepage {
 
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap:50px; 
 
}
form {
    border:none !important;
    padding: 0.5rem !important;
    border-radius: 15px !important;
    margin-top: 0.8rem !important;
}
.content{
  width: 185%;
    padding: 15px !important;
    height: 323px;
    border-radius: 14px;
}
h3 {
  margin-top: 0.5rem !important;
  font-size: 1.1rem !important;
  color: black;
  font-family: "medium";
}
.image-container{
  img{
    border-radius: 14px;
    width: 800px;
  }
}
.input-group {
  margin-bottom: 1rem;
}

button.btn {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 6px;
  background: var(--main);
  border: none;
  transition: background-color 0.3s ease;
  color: #ffffff;
  font-family: "medium";
  cursor: pointer;

  &:hover {
    background-color: var(--primary) !important;
  }
}

</style>