<template>
  <header-pages
    title="المجموعات"
    :show-button="false"
    icon="fa-solid fa-people-group"
  ></header-pages>

  <div class="alll">
    <div class="search">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="بحث عن مجموعة..."
        v-model="word"
        @input="debouncedSearch"
      />
    </div>
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
    
    />
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>
  
  <script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useTeacherGroupStore } from "@/stores/TeacherGroup/teacherGroupStore";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { debounce } from "lodash";

export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      store: useTeacherGroupStore(),
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["ID", "اسم المجموعة", "عدد الطلاب ", "عدد الاختبارات " , "عدد الحصص"],
      viewLink: "/view-group",
    };
  },
  computed: {
    ...mapState(useTeacherGroupStore, {
      teacherGroupss: (state) => state.teacherGroups,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      
    }),
    tableRows() {
  const dataToDisplay = this.teacherGroupss;
  return dataToDisplay.map((emp) => [
    emp.id,
    emp.title,
    emp.user_count,
    emp.exam_count,
    emp.lesson_count
  ]);
},
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },

  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const TeacherGroupsStore = useTeacherGroupStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await TeacherGroupsStore.getTeacherGroups(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await TeacherGroupsStore.getTeacherGroups(1, this.word);

      if (TeacherGroupsStore.teacherGroups.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },

  
    handlePageChange(page) {
      const curriculaStore = useTeacherGroupStore();
      curriculaStore.getTeacherGroups(page);
    },
  },

  async mounted() {
    const TeacherGroupsStore = useTeacherGroupStore();
    await TeacherGroupsStore.getTeacherGroups();

    this.debouncedSearch = debounce(() => {
      this.handleSearch(); 
    }, 700); 
  },
};
</script>
  
  <style scoped>
</style>
  