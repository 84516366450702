import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useCountStore = defineStore("count", {
  state: () => ({
    counter: [],
  }),

  actions: {
    async getCount() {
      try {
        const response = await axios.post("fetch_statistics");
        if (response.data.status === true) {
          this.counter = response.data.data;
        } else {
          console.error("Error fetching statistics:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    },
    async deleteCounter(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("delete_statistic", { id });
          this.counter = this.counter.filter((counter) => counter.id !== id);
          Swal.fire("تم الحذف!", "تم حذف الكاونت", "success");
        }
      } catch (error) {
        console.error("Error deleting count:", error);
        Swal.fire("Error!", "There was an error deleting the header.", "error");
      }
    },
  },
});
