<template>
  <div class="add-lesson">
    <div class="head">
      <h4>عرض حصص المجموعه</h4>
    </div>

    <div class="sqdwel">
      <!-- حقل البحث -->

      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          v-model="searchQuery"
          @input="debounceSearch"
          placeholder="ابحث عن درس"
        />
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>اسم الحلقه</th>
            <th>المرحله</th>
            <th>السورة</th>
            <th>من الايه</th>
            <th>الي الايه</th>
            <th>نوع الحصه</th>
            <th>المدرس</th>
            <th>اليوم</th>
            <th>وقت البدايه</th>
            <th>وقت النهايه</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="lessonTable.length === 0">
            <td colspan="12" style="text-align: center !important">
              لا يوجد حصص
            </td>
          </tr>
          <tr v-for="lesson in lessonTable" :key="lesson.id">
            <td>{{ lesson.id }}</td>
            <td>{{ lesson.title }}</td>
            <td>{{ lesson.stage_title }}</td>
            <td>{{ lesson.surah_title }}</td>
            <td>{{ lesson.start_ayah_title }}</td>
            <td>{{ lesson.end_ayah_title }}</td>
            <td>{{ lesson.session_type }}</td>
            <td>{{ lesson.teacher_name }}</td>
            <td>{{ lesson.date }}</td>
            <td>{{ formatTime(lesson.start_time) }}</td>
            <td>{{ formatTime(lesson.end_time) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination" v-if="pagination.last_page > 1">
      <button
        class="prev"
        :disabled="pagination.current_page <= 1"
        @click="fetchSessions(pagination.current_page - 1)"
      >
        &laquo; السابق
      </button>

      <button
        v-for="page in pagination.last_page"
        :key="page"
        :class="{ active: pagination.current_page === page }"
        @click="fetchSessions(page)"
      >
        {{ page }}
      </button>

      <button
        class="next"
        :disabled="pagination.current_page >= pagination.last_page"
        @click="fetchSessions(pagination.current_page + 1)"
      >
        التالي &raquo;
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "lodash";

export default {
  name: "AddLessonGroup",
  data() {
    return {
      pagination: {},
      links: {},
      debounceTimer: null,
      searchQuery: "",
      lessonTable: [],
    };
  },

  methods: {
    formatTime(time) {
      if (!time) return ""; // التحقق من وجود الوقت
      const [hours, minutes] = time.split(":").map(Number);
      const suffix = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // تحويل 0 إلى 12 في صيغة 12 ساعة
      return `${formattedHours}:${String(minutes).padStart(2, "0")} ${suffix}`;
    },

    async fetchSessions(page = 1) {
      try {
        const res = await axios.post("organization_fetch_sessions", {
          group_id: this.$route.params.groupId,
          page: page,
        });
        if (res.data.status === true) {
          this.lessonTable = res.data.data.data;
          this.pagination = res.data.data.meta;
          this.links = res.data.data.links;
        } else {
          Swal.fire({
            icon: "error",
            title: "فشل تحميل البيانات",
            text: res.data.message || "حدث خطأ أثناء جلب البيانات.",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل تحميل البيانات",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },

    debounceSearch: debounce(function () {
      this.searchLessons();
    }, 700),
    async searchLessons() {
      try {
        const res = await axios.post("organization_fetch_sessions", {
          group_id: this.$route.params.groupId,
          word: this.searchQuery,
        });
        if (res.data.status === true) {
          this.lessonTable = res.data.data.data;
        } else {
          this.lessonTable = [];
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل البحث",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
        this.lessonTable = [];
      }
    },
  },
  watch: {
    is_new(newValue) {
      if (newValue) {
        this.clearInputs();
      }
    },
  },
  async mounted() {
    this.fetchSessions();
  },
};
</script>
<style scoped lang="scss">
.add-lesson {
  padding: 2rem;
}
.all-btn {
  margin-top: 2rem;
}
.lesson {
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.7s;

  border: 1px solid var(--main);
}
.head h4 {
  font-family: "bold";
}
th.sorted {
  background-color: #f0f0f0;
}

th.sorted i {
  margin-left: 5px;
}

th.sorted i.fa-arrow-up {
  color: green;
}

th.sorted i.fa-arrow-down {
  color: red;
}
.selected {
  background-color: #e9e5e5;
}
button.save {
  display: block;
  margin-inline-start: auto;
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.7s;
  width: 25%;
  border: 1px solid var(--main);
}
.table > :not(caption) > * > * {
  background-color: unset !important;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multiselect--disabled {
  background: unset !important;
  opacity: unset !important;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: unset !important;
  color: unset !important;
}
i.fa-solid.fa-trash {
  color: red;
}
.allde {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allde p {
  font-family: "regular";
}
.allde label {
  padding: 0;
  margin: 0;
}
.search-container {
  margin: 1rem 0;
}
.search-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.not-found {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
  margin-top: 1rem;
}
.replace {
  background-color: var(--main);
  font-family: "regular";
  padding: 0.5rem;
  border-radius: 5px;
  color: var(--secondary);
  border: 0;
  margin-right: 10px;
}
.sqdwel {
  border: 1px solid var(--main);
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--main);
      color: #fff;
    }

    &.active {
      background-color: var(--main);
      color: #fff;
      font-weight: bold;
    }

    &:disabled {
      background-color: #eee;
      color: #aaa;
      cursor: not-allowed;
      font-family: "regular";
    }

    &.prev,
    &.next {
      font-size: 16px;
      font-family: "regular";
    }

    &:not(.active) {
      background-color: #f5f5f5;
      color: #333;
    }
  }
}
.row-options {
  margin-bottom: 0.5rem;
}
.modal-dialog.modal-dialog-centered.modal-xl {
  margin-right: 30rem;
}
</style>
