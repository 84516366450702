<template>
  <div class="groups">
    <h4 style="font-family: 'bold'">تعديل مجموعة</h4>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="title">أسم المجموعة</label>
          <div class="input">
            <input
              id="title"
              v-model="title"
              placeholder="أدخل أسم المجموعة"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_date">تاريخ البدايه</label>
          <div class="input">
            <input
              id="start_date"
              v-model="start_date"
              placeholder="من"
              type="date"
            />
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button class="save" type="submit">تعديل</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "EditGroupsShow",
  data() {
    return {
      title: "",
      start_date: "",
      group_id: this.$route.params.groupId,
      course_id: this.$route.params.courseId,
    };
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post("fetch_group_details", {
          id: this.group_id,
        });
        if (res.data.status) {
          this.title = res.data.data.title;
          this.start_date = res.data.data.start_date;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: "لم يتم العثور على بيانات المجموعة",
          });
        }
      } catch (error) {
        console.error("Error fetching group data:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ أثناء جلب بيانات المجموعة",
        });
      }
    },
    async submitForm() {
      if (!this.title.trim() || !this.start_date) {
        Swal.fire({
          icon: "warning",
          title: "تحقق من الحقول",
          text: "يجب تعبئة جميع الحقول",
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append("title", this.title);
        formData.append("start_date", this.start_date);
        formData.append("id", this.group_id);
        formData.append("course_id", this.course_id);

        const res = await axios.post("edit_group", formData);
        if (res.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: res.data.message || "تم تعديل بيانات المجموعة",
          });
          this.$router.push(`/groups-index/${this.course_id}`); // Redirect to the group's details page
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: res.data.message || "حدث خطأ أثناء التعديل",
          });
        }
      } catch (error) {
        console.error("Error updating group:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ أثناء حفظ التعديلات",
        });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
button.save {
  display: block;
  margin-inline-start: auto;
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.7s;
  width: 22%;
  border: 1px solid var(--main);
}
button.bake {
  background-color: transparent;
  color: var(--main);
  font-family: "regular";
  border: 1px solid var(--main);
  padding: 0.8rem 2rem;
  border-radius: 10px;
  transition: 0.3s;
}
button.bake:hover {
  background-color: var(--main);
  color: var(--secondary);
}
.all-btn {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}
</style>
