<template>
  <div v-if="showNavbar" class="main-nav">
    <nav class="navbars">
      <div class="allnav">
        <div style="display: none" class="navbar-toggle" @click="toggleSidebar">
          <i :class="isCollapsed ? 'fas fa-bars' : 'fas fa-times'"></i>
        </div>
        <div class="all-info">
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img :src="image" alt="User Image" />
              <h4>{{ name }}</h4>
            </button>

            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" @click="logoutHandler">logout</a>
              </li>
            </ul>
          </div>

          <a class="navbar-brand" href="#"
            ><img src="@/assets/photos/logo.png" alt=""
          /></a>
          <button class="btn-dark-mode" @click="toggleDarkMode">
            <i :class="isDarkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
    </nav>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth/AuthStore";
import { mapActions, mapState } from "pinia";
export default {
  data() {
    return {
      isDarkMode: false,
      name: "",
      image: "",
    };
  },

  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["data"]),
    showNavbar() {
      // حدد مسار الصفحة التي تريد إخفاء الـ nav فيها
      return this.$route.path !== "/live-teacher";
    },
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      const body = document.body;

      if (this.isDarkMode) {
        body.classList.add("dark-mode");
        body.classList.add("dark-mode");
        localStorage.setItem("darkMode", "true");
      } else {
        body.classList.remove("dark-mode");
        localStorage.setItem("darkMode", "false");
      }
    },

    getNameFromLocalStorage() {
      const user = localStorage.getItem("user");
      if (user) {
        try {
          const parsedUser = JSON.parse(user);
          this.name = parsedUser.name || "";
          this.image = parsedUser.image || "";
        } catch (error) {
          console.error("Error parsing user from local storage:", error);
        }
      } else {
        console.log("No user found in local storage.");
      }
    },
    ...mapActions(useAuthStore, ["logout"]),
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },

    logoutHandler() {
      this.logout();
      this.$router.push("/login");
    },
  },
  mounted() {
    this.getNameFromLocalStorage();
    const darkModeFromStorage = localStorage.getItem("darkMode");
    if (darkModeFromStorage === "true") {
      this.isDarkMode = true;
      document.body.classList.add("dark-mode");
    } else {
      this.isDarkMode = false;
      document.body.classList.remove("dark-mode");
    }
  },
};
</script>
<style scoped>
.darkMode {
  color: #000;
}
.darkMode:hover::after {
  content: "Dark Mode";
  position: absolute;
  top: 70%;
  right: 77%;
  transform: translateX(-50%);
  color: #000;
  font-size: 12px;
  padding: 5px;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
}
</style>
