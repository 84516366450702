<template>
  <div class="forgetpassword">
    <div class="form" @submit.prevent="getEmail">
      <div class="logo">
        <img src="../../../assets/photos/logo.png" alt="" />
        <h4>هل نسيت كلمة المرور ؟</h4>
      </div>
      <form @submit.prevent>
        <label for="email">أدخل البريد الألكتروني</label>
        <div class="input">
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="أدخل البريد الألكتروني"
          />
        </div>
        <div class="bnt">
          <button type="submit">أرسال</button>
        </div>
      </form>
    </div>
    <div class="img">
      <img
        src="../../../assets/photos/svg_new_2.svg"
        alt="Forgot Password Image"
      />
    </div>
  </div>
</template>
<script>
import router from "@/router";
import { useAuthStore } from "@/stores/auth/AuthStore";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ForgetPassword",
  data() {
    return {
      email: "",
      ResetEmail: "",
    };
  },

  methods: {
    // submitCode(){
    //   router.push("/Code");
    // },
    async submitEmail() {
  const authStore = useAuthStore();
  await authStore.submitPasswordResetEmail(this.email);
},
storeEmailInLocalStorage() {
      if (this.email) {
        localStorage.setItem("email", this.email); 
      }
    },
async getEmail(event) {
  try {
    const email = event.target.email.value;

    // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if (!emailPattern.test(email)) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "البريد الإلكتروني غير صالح",
    //     text: "يرجى إدخال بريد إلكتروني صحيح.",
    //   });
    //   return; 
    // }

    const formData = new FormData();
    formData.append("email", email); 

    const response = await axios.post("organization-check-email", formData);

    if (response.data.status === true) {
      this.ResetEmail = response.data.data;

      Swal.fire({
        icon: "success",
        title: "تم إرسال الكود بنجاح",
        text: response.data.message || "تم إرسال الكود بنجاح",
      });
      this.storeEmailInLocalStorage();
      router.push("/Code");
    } else {
      console.error(
        "Error fetching code:",
        response.data.message || "Unknown error"
      );
      Swal.fire({
        icon: "error",
        title: "فشل في إرسال الكود",
        text: response.data.message || "حدث خطأ أثناء إرسال الكود.",
      });
    }
  } catch (error) {
    console.error("An error occurred while sending the email:", error);
    Swal.fire({
      icon: "error",
      title: "حدث خطأ",
      text: "هناك مشكلة في إرسال البريد الإلكتروني.",
    });
  }
}



  },
};
</script>

<style scoped lang="scss">
.forgetpassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(to top, var(--main), var(--primary));
  gap: 10rem;
  padding: 1rem;

  .form {
    width: 100%;
    max-width: 500px;
    background: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
      font-size: 0.875rem;
      color: #333;
      margin-bottom: 0.5rem;
    }

    .input {
      position: relative;

      input {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 6px;
        font-size: 0.875rem;
        outline: none;
        transition: border-color 0.3s ease;

        &:focus {
          border-color: var(--primary);
          box-shadow: 0 0 0 3px var(--primary);
        }
      }
    }

    .bnt {
      display: flex;
      justify-content: center;

      button {
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 6px;
        background: var(--main);
        color: #ffffff;
        font-size: 0.875rem;
        font-family: "medium";
        margin-top: 0.5rem;
        cursor: pointer;
        transition: background 0.3s ease;
        width: 100%;

        &:hover {
          background: var(--primary);
        }
      }
    }
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;

    img {
      width: 100%;
      max-width: 100%;
      border-radius: 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .form,
    .img {
      width: 100%;
      max-width: 100%;
    }

    .img {
      display: none;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "medium";
    h4 {
      margin-top: 0.5rem;
      font-size: 1.1rem;
    }
  }
}
</style>
