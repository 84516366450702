import axios from "axios";
import { defineStore } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";
import { words } from "lodash";

export const useTeacherGroupStore = defineStore("teacherGroup", {
  state: () => ({
    teacherGroups: [],
  }),

  actions: {
    async getTeacherGroups(page = 1, word = "") {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(`teacher_group?page=${page}`, {
            word: word,
        } );
        const paginationStore = usePaginationStore();

        const {
          current_page,
          from,
          last_page,
          per_page,
          to,
          total,
        } = response.data.data.meta;

        if (response.data.status === true) {
          this.teacherGroups = response.data.data.data;

          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingStore.stopLoading();
      }
    },
  },
});
