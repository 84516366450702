<template>
  <div class="all">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12 imgg">
          <div class="images">
            <router-link to="/electronic-reader">
              <img src="../../../assets/photos/maq.png" alt="" />
            </router-link>
            <router-link to="/groups">
              <img src="../../../assets/photos/grop.png" alt="" />
            </router-link>
            <router-link to="/exams">
              <img src="../../../assets/photos/tes.png" alt="" />
            </router-link>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
          <h4>معدل الاحصائيات على الموقع</h4>
          <div class="all-info">
            <div class="infos">
              <div class="info">
                <img src="../../../assets/photos/users.png" alt="" />
                <div class="contentt">
                  <h2>اجمالى الدروس</h2>
                  <h3>25 درس</h3>
                </div>
              </div>
              <div class="info">
                <img src="../../../assets/photos/users.png" alt="" />
                <div class="contentt">
                  <h2>اجمالى الطلاب</h2>
                  <h3>200 طالب</h3>
                </div>
              </div>
            </div>
            <div class="infos">
              <div class="info">
                <img src="../../../assets/photos/users.png" alt="" />
                <div class="contentt">
                  <h2>المجموعات</h2>
                  <h3>6</h3>
                </div>
              </div>
              <div class="info">
                <img src="../../../assets/photos/users.png" alt="" />
                <div class="contentt">
                  <h2>التقييمات</h2>
                  <h3>150 تقييم</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
      style="background-color: #f0eeee; margin-top: 15px; gap: 15px"
    >
      <div class="col-lg-8 col-md-12 col-12 student">
        <div class="percentage_jobs">
          <h5 style="text-align: right">معدل تطور الطالب</h5>
          <div class="card">
            <Chart
              type="bar"
              style="height: 500px"
              :data="chartData"
              :options="chartOptions"
              class="h-[30rem]"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12 col-12 activity">
        <div
          class="lesson"
          style="
            display: flex;
            justify-content: space-between;
            border-radius: 10px;
          "
        >
          <h5>النشاطات القادمة</h5>

          <a href="">
            <h5>عرض الكل</h5>
          </a>
        </div>
        <div class="act">
          <h4>حصة لايف على الجزء الأول من سورة البقرة بالتجويد</h4>
          <h5>مجموعة العهد الحديث</h5>
          <img src="../../../assets/photos/22.png" alt="" />
        </div>

        <div class="act">
          <h4>حصة لايف على الجزء الأول من سورة البقرة بالتجويد</h4>
          <h5>مجموعة العهد الحديث</h5>
          <img src="../../../assets/photos/22.png" alt="" />
        </div>

        <div class="act">
          <h4>حصة لايف على الجزء الأول من سورة البقرة بالتجويد</h4>
          <h5>مجموعة العهد الحديث</h5>
          <img src="../../../assets/photos/22.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "primevue/chart";
export default {
  data() {
    return {
      chartData: null,
      chartOptions: null,
    };
  },
  components: {
    Chart,
  },
  methods: {
    setChartData() {
      return {
        labels: [
          "April",
          "may",
          "jun",
          "july",
          "august",
          "septemper",
          "october",
          "december",
        ],
        datasets: [
          {
            type: "line",
            label: "اجراء المهام",
            backgroundColor: "#3F3F44",
            borderColor: "#3F3F44",
            borderWidth: 1,
            data: [6, 9, 7, 3, 10, 7, 4, 6, 7, 10, 8],
          },
          {
            type: "bar",
            label: "تفاعل  الموظفين",
            backgroundColor: "#21765E",
            borderColor: "#21765E",
            borderWidth: 1,
            data: [10, 7, 4, 6, 9, 7, 3, 10, 13, 8, 11],
          },
          {
            type: "bar",
            label: "معدل تطور الطالب",
            backgroundColor: "#21765E",
            borderColor: "#21765E",
            borderWidth: 1,
            // tension: 1,
            data: [10, 20, 30, 20],
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
    },
    updateChart() {
      this.chartData = this.setChartData(); // Set chart data after fetch
      this.chartOptions = this.setChartOptions(); // Set chart options
    },
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
  },
  watch: {
    // Watch the store for changes in ChartData
    "store.ChartData": function (newData) {
      if (newData) {
        this.updateChart(); // Update the chart when data is available
      }
    },
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: "regular";
}
.activity {
  background-color: white;
  border-radius: 10px;
  width: 32.3%;
  margin-bottom: 178px !important;
}
.dd {
  width: 90%;
  margin-right: 20px;
}
.student {
  background: white;
  border-radius: 14px;
  padding: 11px;
  margin-right: 21px;
  width: 64.8%;
}
.act {
  position: relative;
  border: 1px solid #00000029;
  /* width: fit-content; */
  padding: 0.6rem 3rem;
  margin-top: 16px;
  border-radius: 6px;
  margin-bottom: 10px;
  h4 {
    font-size: 15px;
    margin-right: 24px;
  }
  h5 {
    font-size: 15px;
    margin-right: 24px;
    color: #8d8d8d;
  }

  img {
    position: absolute;
    top: -21px;
    right: -8px;
    height: 74px;
  }
}
.lesson {
  padding: 9px;

  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    color: var(--main);
  }
}
.images {
  background: white;
  border-radius: 10px;

  img {
    margin-top: 59px;
    margin-right: 164px;
    margin-bottom: 64px;
  }
}
h4 {
  font-size: 19px;
  font-family: "regular";
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: 0;
  margin-left: auto;
}
.all-info {
  display: flex;
  gap: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
}
.all {
  background: #f0eeee;
}

.info {
  display: flex;
  align-items: center;
  background-color: #eef2f2;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 1rem 0;
  padding-left: 85px;
  margin-bottom: 33px;
  margin-top: 12px;

  img {
    background-color: #b2d23f;
    padding: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
  h2 {
    font-family: "regular";
    font-size: 17px;
  }
  h3 {
    font-size: 14px;
    font-family: "regular";
    font-weight: bold;
  }
}

@media screen and (max-width: 425px) {
  .images {
    margin-bottom: 15px;
    img {
      margin-right: 110px;
    }
  }
  .all-info {
    display: block;
  }
  .student[data-v-66cbdfec] {
    background: white;
    border-radius: 14px;
    padding: 11px;
    margin-right: 21px;
    width: 89.8%;
}
.activity[data-v-66cbdfec] {
    background-color: white;
    border-radius: 10px;
    width: 90.3%;
    margin-right: 17px;
}
}

@media screen and (max-width: 768px) {
  .images img {
    margin-top: 59px;
    margin-right: 118px;
    margin-bottom: 64px;
}
  .student[data-v-66cbdfec] {
    background: white;
    border-radius: 14px;
    padding: 11px;
    margin-right: 21px;
    width: 93.3%;
}
.activity[data-v-66cbdfec] {
    background-color: white;
    border-radius: 10px;
    width: 94.3%;
    margin-right: 18px;
    margin-bottom: 0 !important;
}


  
}

// @media screen and (max-width: 1440px) {
//   .imgg {
//     flex: 0 0 auto;
//     width: 97.3%;
//     margin-bottom: 16px;
//   }
//   .col-lg-4 {
//     flex: 0 0 auto;
//     width: 97.5%;
//   }
 
//   .student {
//     background: white;
//     border-radius: 14px;
//     padding: 11px;
//     margin-right: 21px;
//     width: 94%;
//   }
//   .activity {
//     flex: 0 0 auto;
//     width: 94.5%;
//     margin-right: 17px;
//   }
//   .info {
//     display: flex;
//     align-items: center;
//     background-color: #eef2f2;
//     border-radius: 10px;
//     padding: 0.5rem;
//     margin: 1rem 0;
//     padding-left: 365px;
//     margin-bottom: 33px;
//     margin-top: 12px;
//   }
// }
</style>