<template>
  <div class="head">
    <h4>تعديل سؤال</h4>
  </div>
  <div class="exams">
    <form @submit.prevent="submitForm">
      <div>
        <div class="question">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <label for="">السؤال</label>
              <div class="input">
                <input type="text" v-model="exam.question" required />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="inputs">
                <label for="">نوع السؤال</label>
                <multiselect
                  :close-on-select="true"
                  :hide-selected="true"
                  placeholder="نوع السؤال"
                  :options="ExamsOptions"
                  track-by="id"
                  label="title"
                  v-model="exam.type"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              <label for="">الدرجة</label>
              <div class="input">
                <input
                  type="number"
                  v-model.number="exam.degree"
                  min="1"
                  required
                />
              </div>
            </div>
            <div class="col-lg-8 col-md-6 col-12">
              <div
                class="d-flex justify-content-between align-items-center gap-2"
                v-for="(answer, index) in exam.answers"
                :key="index"
              >
                <div class="w-100">
                  <label for="">الجواب</label>
                  <div class="input">
                    <input type="text" v-model="answer.answer" required />
                  </div>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <div>
                    <label for="" class="text-nowrap m-0">الجواب الصحيح</label>
                    <div class="input">
                      <input
                        type="radio"
                        :name="`is_correct`"
                        v-model="exam.is_correct"
                        :value="index"
                        class="w-25"
                        @change="setCorrectAnswer(index)"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="removeAnswer(index)"
                    class="btn btn-outline-danger"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </button>
                </div>
              </div>

              <button
                style="font-family: 'regular'"
                type="button"
                @click="addAnswer"
                class="btn"
              >
                <i class="fa fa-plus"></i> إضافة إجابة
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="all-btn mt-4">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="goBack">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "ExamsEidtQuestion",
  data() {
    return {
      ExamsOptions: [
        { id: 1, title: "صوتي" },
        { id: 2, title: "اختياري" },
        { id: 3, title: "توصيل" },
        { id: 4, title: "تصحيح" },
      ],
      exam: {
        question: "",
        type: null,
        degree: null,
        is_correct: null,
        answers: [{ answer: "", is_correct: false }],
      },
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    setCorrectAnswer(selectedIndex) {
      this.exam.answers.forEach((answer, index) => {
        answer.is_correct = index === selectedIndex; // اجعل فقط الإجابة المختارة صحيحة
      });
    },
    removeAnswer(index) {
      this.exam.answers.splice(index, 1); // إزالة الإجابة
      if (this.exam.is_correct === index) {
        this.exam.is_correct = null; // إعادة ضبط الجواب الصحيح إذا تمت إزالته
      }
    },
    addAnswer() {
      this.exam.answers.push({ answer: "", is_correct: false }); // إضافة إجابة جديدة
    },
    goBack() {
      this.$router.go(-1);
    },
    async getData() {
      try {
        const response = await axios.post("fetch_group_exam_question_details", {
          id: this.$route.params.id,
        });

        const data = response.data.data;

        // تعيين السؤال والخصائص الأخرى
        this.exam.question = data.question;

        // تعيين النوع
        this.exam.type = this.ExamsOptions.find(
          (option) => option.id === data.type
        );

        this.exam.degree = data.degree;

        // إعداد الإجابات
        this.exam.answers = data.ansewers.map((answer) => ({
          answer: answer.answer,
          is_correct: answer.is_correct, // تحويل إلى Boolean
        }));

        // تحديد الفهرس للإجابة الصحيحة
        const correctIndex = data.answers.findIndex(
          (answer) => answer.is_correct === true
        );
        this.exam.is_correct = correctIndex >= 0 ? correctIndex : null; // إذا لم توجد إجابة صحيحة، يكون null
      } catch (error) {
        console.error(error);
      }
    },
    async submitForm() {
      const payload = {
        type: this.exam.type.id, // استخدم id فقط
        degree: this.exam.degree,
        question: this.exam.question,
        answers: this.exam.answers.map((answer) => ({
          answer: answer.answer,
          is_correct: answer.is_correct,
        })),
        question_id: this.$route.params.id, // استخدم الـ id للسؤال
      };

      try {
        const response = await axios.post("edit_group_exam_question ", payload);

        // التحقق من نجاح الاستجابة من السيرفر
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم تعديل السؤال بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.go(-1);
          // إعادة التوجيه إلى الصفحة المناسبة بعد التعديل
        } else {
          // في حالة فشل التعديل
          Swal.fire({
            icon: "error",
            title: "فشل تعديل السؤال",
            text: response.data.message || "حدث خطأ أثناء تعديل السؤال.",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        // التعامل مع الأخطاء في حالة حدوث مشكلة أثناء الاتصال بالخادم
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "فشل الاتصال بالخادم",
          text: "حدث خطأ أثناء الاتصال بالخادم. حاول مرة أخرى.",
          showConfirmButton: true,
        });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
