<template>
  <div class="add_teacher">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages title=" إضافة معلم جديد" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!-- <span class="error-feedback" v-if="v$.form.imageSrc.$error">
              {{ getErrorMessage(v$.form.imageSrc) }}
            </span> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label>اضافة صورة الشهادادت الحاصل عليها</label>
          <input
            type="file"
            @change="handleCertificatesChange"
            accept="image/*"
            multiple
            ref="certificatesInput"
            style="display: none"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span> اختيار صوره</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(src, index) in form.certificateImagesSrc"
              :key="index"
              class="certificate-image"
            >
              <img :src="src" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificate(index)"
              ></i>
            </div>
            <!-- <span
              class="error-feedback"
              v-if="v$.form.certificateImagesSrc.$error"
            >
              {{ getErrorMessage(v$.form.certificateImagesSrc) }}
            </span> -->
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الوظيفه</label>
          <multiselect
            v-model="form.select_job"
            :options="postionOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            select-label=""
            :hide-selected="true"
            :clear-on-select="true"
          ></multiselect>
        </div> -->
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اسم المعلم</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل  اسم المعلم"
              v-model="form.name"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.name.$error">
            {{ getErrorMessage(v$.form.name) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 my-2">
          <label for="">رقم الهاتف</label>

          <div class="input">
            <input
              type="tel"
              @input="validateInput"
              placeholder="رقم الهاتف"
              v-model="form.phone"
            />
          </div>

          <span class="error-feedback" v-if="v$.form.phone.$error">
            {{ getErrorMessage(v$.form.phone) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ الميلاد </label>
          <div class="input">
            <input
              type="date"
              placeholder="ادخل تاريخ الميلاد"
              v-model="form.date_of_birth"
              @input="calculateAge"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.date_of_birth.$error">
            {{ getErrorMessage(v$.form.date_of_birth) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="age"> العمر </label>
          <div class="input">
            <input
              type="number"
              placeholder="العمر"
              v-model="form.age"
              readonly
            />
          </div>
          <span class="error-feedback" v-if="v$.form.age.$error">
            {{ getErrorMessage(v$.form.age) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> الوظيفه </label>
          <multiselect
            placeholder=" اختار الوظيفه"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="form.job_type"
            :options="jobsType"
            track-by="id"
            label="title"
          ></multiselect>
          <span class="error-feedback" v-if="v$.form.job_type.$error">
            {{ getErrorMessage(v$.form.job_type) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> المناهج </label>
          <multiselect
            placeholder=" اختار المناهج"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="form.curriculum"
            :options="CurriculaOptions"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
          <span class="error-feedback" v-if="v$.form.curriculum.$error">
            {{ getErrorMessage(v$.form.curriculum) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكترونى </label>
          <div class="input">
            <input
              type="email"
              id="name"
              placeholder="البريد الالكترونى"
              v-model="form.email"
              @blur="validateEmail"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.email.$error">
            {{ getErrorMessage(v$.form.email) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input" id="pass">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="name"
              placeholder="كلمة المرور"
              v-model="form.password"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              <i id="shownn" v-if="showPassword" class="fa fa-eye"></i>
              <i id="hiddenn" v-else class="fa fa-eye-slash"></i>
            </span>
          </div>

          <span class="error-feedback" v-if="v$.form.password.$error">
            {{ getErrorMessage(v$.form.password) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="identitynumber">
            <label for=""> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="text"
                placeholder="ادخل الرقم الهويه"
                v-model="form.identity_number"
              />
            </div>
            <span class="error-feedback" v-if="v$.form.identity_number.$error">
              {{ getErrorMessage(v$.form.identity_number) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="gg">
            <div class="radone">
              <!-- {{ form.gender }} -->
              <label for="gender">النوع</label>
              <div class="radio">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    v-model="form.gender"
                    value="1"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    ذكر
                  </label>
                </div>
                <div class="form-check">
                  <input
                    v-model="form.gender"
                    class="form-check-input"
                    value="0"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    أنثي
                  </label>
                </div>
              </div>
            </div>
            <div class="radone">
              <label for="name">نوع الهوية</label>
              <div class="radio">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    v-model="form.identity_type"
                    value="0"
                    type="radio"
                    name="card"
                    id="flexRadioDefault5"
                  />
                  <label class="form-check-label" for="flexRadioDefault5">
                    بطاقة
                  </label>
                </div>
                <div class="form-check">
                  <input
                    v-model="form.identity_type"
                    class="form-check-input"
                    value="1"
                    type="radio"
                    name="stay"
                    id="flexRadioDefault6"
                    checked
                  />
                  <label class="form-check-label" for="flexRadioDefault6">
                    اقامه
                  </label>
                </div>
              </div>
            </div>
            <div class="radone">
              <label for="" class=""> الحالة الاجتماعية </label>
              <div class="d-flex">
                <div class="form-check d-flex gap-2">
                  <label class="form-check-label" for="flexRadioDefault7">
                    متزوج
                  </label>
                  <input
                    v-model="form.marital_status"
                    class="form-check-input"
                    value="1"
                    type="radio"
                    name="marital_status"
                    id="flexRadioDefault7"
                    checked
                  />
                </div>
                <div class="form-check d-flex gap-2">
                  <label class="form-check-label" for="flexRadioDefault8">
                    اعزب
                  </label>
                  <input
                    v-model="form.marital_status"
                    class="form-check-input"
                    value="0"
                    type="radio"
                    name="marital_status"
                    id="flexRadioDefault8"
                    checked
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-6 col-12">
          <div class="checkbox">
            <form class="prof">
              <input
                type="checkbox"
                id="vehicle1"
                name="make_employee"
                :checked="form.make_employee === 1"
                @change="toggleMakeEmployee"
              />
              <label for="vehicle1">اضافة المعلم الى قائمة المشرفين</label>
            </form>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import useVuelidate from "@vuelidate/core";
import { useTeacherAddStore } from "@/stores/teacher/teacherStoreAdd";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      showPassword: false,

      jobsType: [],

      maritals_status: [
        {
          id: 0,
          title: "اعزب",
        },
        {
          id: 1,
          title: "متزوج",
        },
      ],
      job_type_id: 1,
      store: useTeacherAddStore(),
      v$: useVuelidate(),
      form: {
        job_type_id: "",
        is_employed: 1,
        // select_job: "",
        name: "",
        date_of_birth: "",
        phone: "",
        gender: 0,
        identity_type: 1,
        identity_number: "",
        marital_status: 0,
        curriculum_ids: [],
        email: "",
        password: "",
        age: 0,
        certificate_images: [], // Initialize the array
        certificateImagesSrc: [], // Initialize the array for image previews
        job_type: "",
        curriculum: "",
        make_employee: 1,
      },
      postionOptions: [
        {
          id: 1,
          title: "معلم",
        },
        {
          id: 2,
          title: "موظف",
        },
      ],
      CurriculaOptions: [],
    };
  },
  validations() {
    return {
      form: {
        // imageSrc: { required },
        name: { required },
        date_of_birth: { required },
        gender: { required },
        identity_type: { required },
        identity_number: { required },
        marital_status: { required },
        curriculum: { required },
        email: { required },
        password: { required },
        age: { required },
        phone: { required },
        job_type: { required },
        // certificateImagesSrc: { required },
      },
    };
  },

  methods: {
    calculateAge() {
      if (!this.form.date_of_birth) return; // التأكد من أن تاريخ الميلاد موجود

      const birthDate = new Date(this.form.date_of_birth);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      // إذا لم يكن قد مر الشهر الحالي بعد من تاريخ الميلاد
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      this.form.age = age; // تحديث العمر المحسوب
    },

    toggleMakeEmployee() {
      // تغيير قيمة make_employee بين 1 و 0 عند التبديل
      this.form.make_employee = this.form.make_employee === 1 ? 0 : 1;
    },
    validateEmail(event) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const value = event.target.value;

      if (!emailPattern.test(value)) {
        this.emailError = "Email format not valid";

        // عرض نافذة منبثقة باستخدام SweetAlert
        Swal.fire({
          icon: "error",
          title: "خطأ في البريد الإلكتروني",
          text: "يرجى إدخال بريد إلكتروني صحيح.",
          confirmButtonText: "حسناً",
        });
      } else {
        this.emailError = ""; // إزالة رسالة الخطأ إذا كانت الصيغة صحيحة
      }
    },

    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
    },
    validateInput() {
      this.form.phone = this.form.phone.replace(/[^0-9]/g, "");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    removeCertificate(index) {
      this.form.certificate_images.splice(index, 1);
      this.form.certificateImagesSrc.splice(index, 1);
    },

    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      }
      this.form.job_type_id = this.form.job_type.id;
      this.form.curriculum_ids = this.form.curriculum.map((item) => {
        return item.id;
      });

      this.store.addTeacher(this.form);
      console.log(this.form, "form");
    },
  },
  mounted() {
    this.store.getCurriculums().then(() => {
      this.CurriculaOptions = this.store.curriculums;
    });
    this.store.getJobTypes().then(() => {
      this.jobsType = this.store.jobTypes;
    });
  },
};
</script>
<style scoped lang="scss">
.prof {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.gg {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}
#pass {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.11);
  background-color: #e8f0fe;
  border-radius: 15px;
  margin-top: 0.3rem;
  input {
    border: 0 !important;
    padding: 0.75rem !important;
    margin-top: 0;
  }
}
</style>
