import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useOpinionClientStoreStore = defineStore("opinionClientStore", {
  state: () => ({
    opinionClientStore: [],
  }),
  actions: {
    async fetchOpinionClientStore(page = 1) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(`fetch_opinions?page=${page}`);
        const paginationStore = usePaginationStore();
        // const { current_page, from, last_page, per_page, to, total } =
        //   response.data.data.meta;

        if (response.data.status) {
          this.opinionClientStore = response.data.data;

          // paginationStore.setPage(current_page);
          // paginationStore.setfrom(from);
          // paginationStore.setlastpage(last_page);
          // paginationStore.setperpage(per_page);
          // paginationStore.setto(to);
          // paginationStore.settotal(total);
        }
      } catch (error) {
        // console.error("Error fetching fetchOpinionClientStore:", error);
      } finally {
        loadingStore.stopLoading();
      }
    },

    async deleteOpinionClientStore(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });

        if (result.isConfirmed) {
          await axios.post("delete_opinion", { id });
          this.opinionClientStore = this.opinionClientStore.filter(
            (emp) => emp.id !== id
          );
          Swal.fire("تم الحذف!", "تم حذف راي العميل", "success");
        }
      } catch (error) {
        console.error("Error deleting employee:", error);
        Swal.fire(
          "Error!",
          "There was an error deleting the employee.",
          "error"
        );
      }
    },
  },
});
