<template>
  <div class="main-teacher">
    <h1>تفاصيل المعلم</h1>
    <div class="main-details" v-if="teacher">
      <!-- Collapsible Section: Teacher Image -->
      <div class="section">
        <div class="section-header" @click="toggleSection('image')">
          <h3>صورة المعلم</h3>
          <span class="toggle-icon">{{ isImageVisible ? "▲" : "▼" }}</span>
        </div>
        <div v-if="isImageVisible" class="section-content">
          <img :src="teacher.image" alt="Teacher Image" class="teacher-image" />
        </div>
      </div>

      <!-- Collapsible Section: Additional Images -->
      <div class="section">
        <div class="section-header" @click="toggleSection('additionalImages')">
          <h3>صور الشهادات</h3>
          <span class="toggle-icon">{{
            isAdditionalImagesVisible ? "▲" : "▼"
          }}</span>
        </div>
        <div v-if="isAdditionalImagesVisible" class="section-content">
          <div class="images">
            <div
              v-for="(image, index) in teacher.images"
              :key="index"
              class="image-container"
            >
              <img
                :src="image.image"
                alt="Teacher Additional Image"
                class="additional-image"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Collapsible Section: Teacher Details -->
      <div class="section">
        <div class="section-header" @click="toggleSection('details')">
          <h3>تفاصيل المعلم</h3>
          <span class="toggle-icon">{{ isDetailsVisible ? "▲" : "▼" }}</span>
        </div>
        <div v-if="isDetailsVisible" class="section-content">
          <h4><span>الاسم :</span> {{ teacher.name }}</h4>
          <h4><span>البريد الإلكتروني :</span> {{ teacher.email }}</h4>
          <h4><span>رقم الهاتف :</span> {{ teacher.phone }}</h4>
          <h4><span>الوظيفة :</span> {{ teacher.job_type.title }}</h4>
          <h4><span>تاريخ الميلاد :</span> {{ teacher.date_of_birth }}</h4>
          <h4><span>رقم الهوية :</span> {{ teacher.identity_number }}</h4>
          <h4>
            <span>النوع :</span> {{ teacher.gender === 1 ? "ذكر" : "انثى" }}
          </h4>
          <h4>
            <span>المناهج :</span>
            {{
              teacher.curriculums
                .map((curriculum) => curriculum.title)
                .join(", ")
            }}
          </h4>
          <h4>
            <span>الحالة الاجتماعية :</span>
            {{ teacher.marital_status === 1 ? "متزوج" : "أعزب" }}
          </h4>
        </div>
      </div>
    </div>
    <div v-else>
      <p>جاري تحميل تفاصيل المعلم...</p>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import swal from "sweetalert2";

export default {
  data() {
    return {
      teacher: null,
      isImageVisible: true,
      isAdditionalImagesVisible: true,
      isDetailsVisible: true,
    };
  },
  methods: {
    async getTeacher() {
      try {
        const response = await axios.post("fetch_employee_details", {
          id: this.$route.params.id,
        });
        if (response.data.status === true) {
          this.teacher = response.data.data;
        } else {
          swal.fire({
            title: "خطأ",
            text: "فشل في جلب بيانات المعلم",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching teacher:", error);
        swal.fire({
          title: "خطأ",
          text: "حدث خطأ أثناء جلب البيانات",
          icon: "error",
        });
      }
    },
    toggleSection(section) {
      if (section === "image") {
        this.isImageVisible = !this.isImageVisible;
      } else if (section === "additionalImages") {
        this.isAdditionalImagesVisible = !this.isAdditionalImagesVisible;
      } else if (section === "details") {
        this.isDetailsVisible = !this.isDetailsVisible;
      }
    },
  },
  mounted() {
    this.getTeacher();
  },
};
</script>
  
  <style scoped>
.main-teacher {
  padding: 30px;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border-radius: 12px;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50;
  font-weight: 700;
  font-family: "bold";
}

.main-details {
  background: #ffffff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f4f4f4;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "bold";
}

.section-header:hover {
  background-color: #e0e0e0;
}

.section-header h3 {
  margin: 0;
  font-size: 20px;
  color: #2c3e50;
}

.toggle-icon {
  font-size: 18px;
  color: #2c3e50;
}

.section-content {
  padding: 15px;
  background-color: #ffffff;
}

.teacher-image {
  max-width: 200px;
  border-radius: 50%;
  border: 4px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
}

.images {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container {
  width: 120px;
  height: 120px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.additional-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  font-family: "regular";
}

h4 span {
  font-family: "regular";
  color: #2c3e50;
}

@media (max-width: 768px) {
  .teacher-image {
    max-width: 150px;
  }

  .image-container {
    width: 100px;
    height: 100px;
  }

  h1 {
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
  }
}
</style>