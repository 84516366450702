<template>
  <div class="employees-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages title="اضافة امتحان" :showButton="false" class="mb-4" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الأمتحان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم الأمتحان"
              v-model="name"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_time">وقت البدايه</label>
          <div class="input">
            <input
              type="time"
              name="start_time"
              id=""
              v-model="start_time"
              @input="calculateDuration"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="end_time"> وقت النهايه </label>
          <div class="input">
            <input
              type="time"
              name="end_time"
              id=""
              v-model="end_time"
              @input="calculateDuration"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="duration">مدة الأمتحان</label>
          <div class="input">
            <input type="text" id="duration" v-model="duration" disabled />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_date">تاريخ البدايه</label>
          <div class="input">
            <input type="date" name="start_date" id="" v-model="start_date" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ النهايه </label>
          <div class="input">
            <input type="date" v-model="end_date" />
          </div>
        </div>
      </div>

      <div class="all-btn mt-4">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
  
  <script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ExamsAddGroup",
  data() {
    return {
      name: "",
      duration: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
    };
  },
  components: {
    HeaderPages,
  },
  methods: {
    validateForm() {
      if (
        !this.name ||
        !this.duration ||
        !this.start_date ||
        !this.end_date ||
        !this.start_time ||
        !this.end_time
      ) {
        Swal.fire("خطاء", "جميع الحقول مطلوبة", "error");
        return false;
      }
      if (new Date(this.start_date) > new Date(this.end_date)) {
        Swal.fire(
          "خطاء",
          "تاريخ البداية يجب أن يكون قبل تاريخ النهاية",
          "error"
        );
        return false;
      }
      return true;
    },
    async submitForm() {
      if (!this.validateForm()) return;

      const formData = {
        name: this.name,
        duration: this.duration,
        start_date: this.start_date,
        end_date: this.end_date,
        start_time: this.start_time,
        end_time: this.end_time,
        group_id: this.$route.params.id, // Include group_id here
      };
      try {
        const res = await axios.post("add_group_exam", formData);
        if (res.data.status == true) {
          Swal.fire("تم الحفظ بنجاح", res.data.message, "success");
          this.$router.go(-1);
        } else {
          Swal.fire("خطاء", res.data.message, "error");
          console.error("Failed to add exam:", res.data.message);
        }
      } catch (error) {
        Swal.fire("خطاء", "حدث خطأ أثناء إضافة الامتحان", "error");
        console.error("Error adding exam:", error);
      }
    },
    calculateDuration() {
      if (this.start_time && this.end_time) {
        // تحويل الوقت إلى دقائق
        const [startHours, startMinutes] = this.start_time
          .split(":")
          .map(Number);
        const [endHours, endMinutes] = this.end_time.split(":").map(Number);

        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        // حساب الفرق
        let diffMinutes = endTotalMinutes - startTotalMinutes;

        // التأكد من أن الوقت موجب
        if (diffMinutes < 0) {
          diffMinutes += 24 * 60; // إضافة 24 ساعة إذا كان الوقت سالبًا
        }

        // تحويل الدقائق إلى صيغة الوقت HH:MM
        const hours = Math.floor(diffMinutes / 60);
        const minutes = diffMinutes % 60;
        this.duration = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      } else {
        this.duration = "";
      }
    },
  },
};
</script>
  