<template>
  <div class="groups">
    <div class="head">
      <h4>المجموعات</h4>
      <router-link :to="`/add-group/${$route.params.id}`">
        <button>اضافة مجموعة</button>
      </router-link>
    </div>
  </div>
  <div class="alll">
    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>اسم المجموعه</th>
          <th>تاريخ البدايه</th>

          <th>الاعدادات</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="groups.length === 0">
          <td colspan="5" style="text-align: center">لا توجد مجموعات متاحة.</td>
        </tr>
        <tr v-for="group in groups" :key="group.id">
          <td>{{ group.id }}</td>
          <td>{{ group.title }}</td>
          <td>{{ group.start_date }}</td>
          <td class="puttons">
            <div class="allpyn">
              <i
                style="color: red; font-size: 1rem; cursor: pointer"
                class="fa-regular fa-trash-can"
                @click="deleteGroup(group.id)"
              ></i>

              <router-link
                :to="
                  '/edit-groups-show/' + this.$route.params.id + '/' + group.id
                "
              >
                <i
                  style="color: var(--main); font-size: 1rem"
                  class="fa-regular fa-pen-to-square"
                ></i>
              </router-link>
              <router-link :to="'/exams-group/' + group.id" title="الامتحانات">
                <i
                  style="color: var(--main); font-size: 1rem"
                  class="fa-solid fa-sheet-plastic"
                ></i>
              </router-link>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    style="color: var(--secondary)"
                    class="fa-solid fa-gears"
                  ></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                      :to="`/add-lesson-group/${$route.params.id}/${group.id}`"
                    >
                      اضافة الحلقات
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      :to="`/add-student-group/${$route.params.id}/${group.id}`"
                    >
                      اضافة طلاب للمجموعه
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="`/show-student-group/${$route.params.id}/${group.id}`"
                    >
                      عرض طلاب المجموعه
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="`/view-group/${group.id}`">
                      عرض حصص المجموعه
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
  <script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "GroupsIndexShow",
  data() {
    return {
      groups: [],
      // id: this.$route.params.id,
    };
  },
  methods: {
    async fetchGroups() {
      try {
        const res = await axios.post("fetch_groups", {
          course_id: this.$route.params.id,
        });
        if (res.data.status == true) {
          this.groups = res.data.data.data;
        } else {
          console.error("Failed to fetch groups:", res.data.message);
        }
      } catch (error) {
        console.error("خطاء في جلب المجموعات:", error);
      }
    },
    async deleteGroup(groupId) {
      try {
        const confirm = await Swal.fire({
          title: "هل أنت متأكد؟",
          text: "لن تتمكن من استعادة هذه المجموعة بعد الحذف!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "نعم، احذفها!",
          cancelButtonText: "إلغاء",
        });

        if (confirm.isConfirmed) {
          const res = await axios.post("delete_group", { id: groupId });
          if (res.data.status === true) {
            Swal.fire(
              "تم الحذف!",
              res.data.message || "تم حذف المجموعة بنجاح",
              "success"
            );
            this.groups = this.groups.filter((group) => group.id !== groupId);
          } else {
            Swal.fire(
              "خطأ!",
              res.data.message || "حدث خطأ أثناء الحذف",
              "error"
            );
          }
        }
      } catch (error) {
        console.error("خطأ أثناء الحذف:", error);
        Swal.fire("خطأ!", "حدث خطأ غير متوقع", "error");
      }
    },
  },
  mounted() {
    this.fetchGroups();
  },
};
</script>
  
  <style scoped>
.allpyn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h4 {
  font-family: "regular";
}
button {
  background-color: var(--main);
  color: var(--secondary);
  border-radius: 5px;
  border: 0;
  padding: 0.5rem 1rem;
  font-family: "regular";
  transition: 0.7s;
  border: 1px solid var(--main);
}
.dropdown-toggle::after {
  display: none !important;
}
ul.dropdown-menu.show {
  padding: 0.5rem;
  text-align: start;
  width: 167px;
}
a {
  text-decoration: none;
  color: var(--main);
}
</style>
  