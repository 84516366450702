<template>
  <header-pages title="الحصص"></header-pages>
  <div class="fillter">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <multiselect
          v-model="disability"
          :options="disabilities_ids"
          :clear-on-select="true"
          :close-on-select="false"
          :hide-selected="true"
          :multiple="true"
          select-label=""
          deselect-label=""
          placeholder="اختر الاعاقة"
          label="title"
          track-by="id"
          @update:modelValue="fetchFilteredLessons"
        ></multiselect>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <multiselect
          v-model="curricula"
          :options="curriculum_ids"
          :clear-on-select="true"
          :close-on-select="false"
          :hide-selected="true"
          :multiple="true"
          select-label=""
          deselect-label=""
          placeholder="اختر المنهج"
          label="title"
          track-by="id"
          @update:modelValue="handleDisabilityChange"
        ></multiselect>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <multiselect
          v-model="stage"
          :options="stages_ids"
          :clear-on-select="true"
          :close-on-select="false"
          :hide-selected="true"
          :multiple="true"
          select-label=""
          deselect-label=""
          placeholder="اختر المرحله"
          label="title"
          track-by="id"
          @update:modelValue="fetchFilteredLessons"
        ></multiselect>
      </div>
    </div>
  </div>
  <div class="view-group">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>id</th>
            <th>اسم المنهج</th>
            <th>المرحله</th>
            <th>الاعاقه</th>
            <th>الحصه</th>
            <th>السوره</th>
            <th>من ايه</th>
            <th>الي ايه</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="lessons.length === 0">
            <td colspan="9" class="text-center">لا توجد بيانات لعرضها</td>
          </tr>
          <tr v-for="(lesson, index) in lessons" :key="index">
            <td>{{ lesson.id }}</td>
            <td>{{ lesson.curriculum_title }}</td>
            <td>{{ lesson.stage_title }}</td>
            <td>{{ lesson.disability_types }}</td>
            <td>{{ lesson.title }}</td>
            <td>{{ lesson.surah_title }}</td>
            <td>{{ lesson.start_ayah }}</td>
            <td>{{ lesson.end_ayah }}</td>
            <td>
              <router-link :to="`/lessonsview/${lesson.id}`">
                <button class="show-lesson">
                  <i class="fa-solid fa-eye"></i>
                </button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination Section -->
    <div class="pagination">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: !pagination.prev }">
            <a
              class="page-link"
              href="#"
              aria-label="Previous"
              @click.prevent="getLessons(pagination.prev)"
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          <li
            class="page-item"
            v-for="page in pagination.last_page"
            :key="page"
            :class="{ active: page === pagination.current_page }"
          >
            <a class="page-link" href="#" @click.prevent="getLessons(page)">
              {{ page }}
            </a>
          </li>

          <li class="page-item" :class="{ disabled: !pagination.next }">
            <a
              class="page-link"
              href="#"
              aria-label="Next"
              @click.prevent="getLessons(pagination.next)"
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import axios from "axios";

export default {
  name: "lessonsView",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      disabilities_ids: [],
      disability: "", // قيمة الـ disability التي تم اختيارها
      curriculum_ids: [],
      curricula: "",
      stages_ids: [],
      stage: "",
      lessons: [],
      pagination: {},
    };
  },
  methods: {
    handleDisabilityChange() {
      this.fetchFilteredLessons();
      if (this.curricula && this.curricula.length > 0) {
        this.getstages();
      }
    },

    async fetchFilteredLessons() {
      try {
        // استخراج ids المختارة فقط إذا كانت موجودة
        const selectedDisabilityId =
          this.disability && this.disability.length > 0
            ? this.disability.map((item) => item.id)
            : null;
        const selectedcurriculumId =
          this.curricula && this.curricula.length > 0
            ? this.curricula.map((item) => item.id)
            : null;
        const selectedstagesId =
          this.stage && this.stage.length > 0
            ? this.stage.map((item) => item.id)
            : null;

        const requestData = {};
        if (selectedDisabilityId)
          requestData.disability_ids = selectedDisabilityId;
        if (selectedcurriculumId)
          requestData.curriculum_ids = selectedcurriculumId;
        if (selectedstagesId) requestData.stage_ids = selectedstagesId;

        // إرسال الطلب إلى الـ API مع الفلاتر أو بدونها
        const response = await axios.post(
          "organization_fetch_admin_sessions",
          requestData
        );

        if (response.data.status === true) {
          this.lessons = response.data.data.data;
          this.pagination = response.data.data.meta;
        }
      } catch (error) {
        console.error("Error fetching filtered lessons:", error);
      }
    },

    async getLessons(page) {
      try {
        const url = page
          ? `organization_fetch_admin_sessions?page=${page}`
          : "organization_fetch_admin_sessions";

        const response = await axios.post(url);
        if (response.data.status === true) {
          this.lessons = response.data.data.data;
          this.pagination = response.data.data.meta;
        }
      } catch (error) {
        console.error("Error fetching lessons:", error);
      }
    },

    async getDisabilities() {
      const response = await axios.post("organization_fetch_disabilities");
      if (response.data.status === true) {
        this.disabilities_ids = response.data.data;
      }
    },
    async getcurriculas() {
      const response = await axios.get("organization_fetch_curriculums");
      if (response.data.status === true) {
        this.curriculum_ids = response.data.data;
      }
    },
    async getstages() {
      const response = await axios.post(
        "organization_fetch_curriculums_stages",
        {
          curriculum_ids: Array.isArray(this.curricula)
            ? this.curricula.map((c) => c.id)
            : [],
        }
      );
      if (response.data.status === true) {
        this.stages_ids = response.data.data;
      }
    },
  },
  mounted() {
    this.getDisabilities();
    this.getcurriculas();
    this.getLessons();
  },
};
</script>
<style scoped lang="scss">
.fillter {
  margin-bottom: 1rem;
  margin-top: -3rem;
}
.show-lesson {
  background-color: var(--main);
  color: var(--secondary);
  font-family: "regular";
  border: 0;
  width: 32%;
  padding: 0.4rem 0.5rem;
  border-radius: 10px;
  transition: 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table {
  text-align: center !important;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  .pagination-button {
    background-color: var(--main);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: "regular";

    &:hover {
      background-color: var(--main);
    }

    &:disabled,
    &.disabled {
      background-color: #bdc3c7;
      cursor: not-allowed;
    }
  }

  .pagination-info {
    font-size: 1rem;
    color: #2c3e50;
    font-weight: 500;

    strong {
      font-weight: bold;
      color: var(--main);
    }
  }
}

.show-lesson i {
  color: white;
}
a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}
.pagination {
  font-family: "regular";
}
</style>