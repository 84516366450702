<template>
  <div class="all">
    <div class="newpass">
      <form @submit.prevent="resetPassword">
        <div style="text-align: center">
          <img src="../../../assets/photos/logo.png" alt="logo" />
          <h3>تغيير كلمه المرور</h3>
        </div>

        <div class="reset">
          <label style="display: block" for="newPassword">كلمه المرور الجديده</label>
          <input 
            type="password" 
            id="newPassword" 
            v-model="resetPass" 
            placeholder="كلمه المرور الجديده" 
            required 
          />

          <label for="confirmPassword">تاكيد كلمه المرور الجديده</label>
          <input 
            type="password" 
            id="confirmPassword" 
            v-model="confirmPass" 
            placeholder="تاكيد كلمه المرور الجديده" 
            required 
          />

          <button class="btn mt-3" type="submit">استرجاع كلمه المرور</button>
        </div>
      </form>
    </div>

    <div class="img">
      <img
        src="../../../assets/photos/security-password-concept-illustration_251005-470.jpg"
        alt="password reset"
      />
    </div>
  </div>
</template>

<script>
import router from '@/router';
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
  name: "ResetPassword",
  data() {
    return {
      resetPass: "",
      confirmPass: "",  
    };
  },
  methods: {
    validatePassword() {
      if (this.resetPass !== this.confirmPass) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "كلمة المرور الجديدة وتأكيد كلمة المرور لا يتطابقان.",
        });
        return false;
      }
      return true;
    },

    async resetPassword() {
  if (!this.validatePassword()) return;

  try {
    const email = localStorage.getItem("email");
    
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", this.resetPass);

    const response = await axios.post("organization-reset-password", formData);

    if (response.data.status === true) {
      Swal.fire({
        icon: "success",
        title: "تم تغيير الباسورد بنجاح",
        text: response.data.message || "تم تغيير الباسورد بنجاح",
      });
      router.push("/login"); 
    } else {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: response.data.message,
      });
    }
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "حدث خطأ",
      text: "هناك مشكلة أثناء تغيير كلمة المرور.",
    });
  }
},
  },
};
</script>

<style scoped lang="scss">
.all {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, var(--main), var(--primary));
  height: 100vh;
  gap: 150px;
}

.img {
  max-width: 700px;
  img {
    height: 600px;
    border-radius: 16px;
  }
}

label {
  display: block;
}

form {
  background-color: white;
  padding: 15px !important;
}

.newpass {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 15rem; 
  width: 30%;
}

input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--placeholder) !important;
  height: 44px;
  padding: 5px;
}

h3 {
  margin-top: 0.5rem !important;
  font-size: 1.1rem !important;
  color: black;
  font-family: "medium";
}

button.btn {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 6px;
  background: var(--main);
  border: none;
  transition: background-color 0.3s ease;
  color: #ffffff;
  font-family: "medium";
  cursor: pointer;

  &:hover {
    background-color: var(--primary) !important;
  }
}
</style>
