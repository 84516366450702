<template>
  <div class="exams">
    <div class="head">
      <h4>الاسئله</h4>
      <router-link :to="'/add-question-exmas/' + $route.params.id">
        <button class="add">اضافة سؤال</button>
      </router-link>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">اسم السؤال</th>
            <th scope="col">نوع السؤال</th>
            <th scope="col">درجة السؤال</th>
            <th scope="col">الاجابه الصحيحه</th>

            <th scope="col">action</th>
          </tr>
        </thead>
        <tbody></tbody>
        <tr v-if="exams.length === 0">
          <td colspan="9" class="text-center">لا توجد بيانات لعرضها</td>
        </tr>
        <tr v-for="(exam, index) in exams" :key="index">
          <td>{{ exam.id }}</td>
          <td>{{ exam.question }}</td>
          <td>
            {{
              exam.type == 1
                ? "صوتي"
                : exam.type == 2
                ? "اختياري"
                : exam.type == 3
                ? "توصيل"
                : exam.type == 4
                ? "تصحيح"
                : ""
            }}
          </td>

          <td>{{ exam.degree }}</td>
          <td>
            {{ exam.correct_answer }}
          </td>

          <td>
            <i
              class="fa-solid fa-trash"
              style="color: red"
              @click="DeletRow(exam.id)"
            ></i>

            <i
              style="color: var(--main)"
              class="fa-solid fa-pen-to-square"
              @click="EditRow(exam.id)"
            ></i>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      exams: [],
    };
  },
  methods: {
    async fetchExams() {
      try {
        const res = await axios.post("fetch_group_exam_questions ", {
          id: this.$route.params.id,
        });
        if (res.data.status == true) {
          this.exams = res.data.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    EditRow(id) {
      this.$router.push(`/exams-eidt-question/${id}`);
    },
    async DeletRow(id) {
      try {
        const result = await Swal.fire({
          title: "هل أنت متأكد من عملية المسح؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
          cancelButtonText: "إلغاء",
        });

        // إذا اختار المستخدم التأكيد
        if (result.isConfirmed) {
          const res = await axios.post("delete_group_exam_question", { id });

          if (res.data.status === true) {
            // إظهار رسالة النجاح
            await Swal.fire({
              icon: "success",
              title: "تم الحذف بنجاح",
              text: res.data.message,
              timer: 500,
              showConfirmButton: false,
            });
            this.fetchExams();
          } else {
            // إذا فشلت عملية الحذف
            Swal.fire({
              icon: "error",
              title: "فشل الحذف",
              text: res.data.message || "حدث خطأ أثناء عملية الحذف.",
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل الحذف",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
  },
  mounted() {
    this.fetchExams();
  },
};
</script>
<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-family: "bold";
  }
  button {
    background-color: var(--main);
    color: var(--secondary);
    border-radius: 5px;
    border: 0;
    padding: 0.5rem 1rem;
    font-family: "regular";
    transition: 0.7s;
    border: 1px solid var(--main);
  }
}
</style>