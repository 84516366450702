<template>
  <div class="courses-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <header-pages
        :showButton="false"
        link="/edit-courses"
        title="اضافة الدوره"
        class="mb-4"
      />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الدورة</label>
          <div class="input">
            <input
              v-model="Courses.name"
              placeholder="أدخل أسم الدورة"
              type="text"
            />
            <span class="error-feedback" v-if="v$.Courses.name.$error">
              {{ getErrorMessage(v$.Courses.name) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">السنوات الدراسيه</label>
          <multiselect
            :options="yearsOptions"
            v-model="Courses.year_id"
            :multiple="false"
            placeholder="السنوات الدراسيه"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
          ></multiselect>
          <span class="error-feedback" v-if="v$.Courses.year_id.$error">
            {{ getErrorMessage(v$.Courses.year_id) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">الفصول الدراسيه</label>
          <multiselect
            v-model="Courses.season_id"
            :options="seasonOptions"
            :multiple="false"
            placeholder="الفصول الدراسيه"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
          ></multiselect>
          <span class="error-feedback" v-if="v$.Courses.season_id.$error">
            {{ getErrorMessage(v$.Courses.season_id) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">المناهج</label>
          <multiselect
            :options="curriculaOptions"
            v-model="Courses.curriculum_id"
            :multiple="false"
            placeholder="المناهج"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
          ></multiselect>
          <!-- <div style="display: flex; align-items: center">
            <input
              v-model="Courses.all_curriculum"
              type="checkbox"
              id="name"
              @change="changeAllCurriculum"
            />
            <label for="name">المنهج كامل (جميع المراحل)</label>
          </div> -->
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">المراحل</label>
          <multiselect
            v-if="showStages"
            :options="stagesOptions"
            v-model="Courses.stage_ids"
            :multiple="true"
            placeholder="المراحل"
            label="title"
            track-by="id"
            @change="filterDisabilities"
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Disability">الاعاقات</label>
          <multiselect
            v-if="showDisability"
            :options="filteredDisabilityOptions"
            v-model="Courses.disability_ids"
            :multiple="true"
            placeholder="الاعاقات"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
          ></multiselect>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useCoursesAddStore } from "@/stores/courses/CoursesAddStore";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useVuelidate(),
      store: useCoursesAddStore(),
      showStages: true,
      showDisability: true,

      Courses: {
        name: "",
        year_id: null,
        stage_ids: [],
        curriculum_id: null,
        disability_ids: [],
        season_id: null,
        all_curriculum: false,
      },
      yearsOptions: [],
      stagesOptions: [],
      curriculaOptions: [],
      DisapilityOptions: [],
      seasonOptions: [],
      filteredDisabilityOptions: [],
    };
  },
  components: {
    HeaderPages,
    Multiselect,
  },
  validations() {
    return {
      Courses: {
        name: { required },
        year_id: { required },
        stage_ids: { required },
        curriculum_id: { required },

        season_id: { required },
      },
    };
  },
  methods: {
    async getData() {
      try {
        await this.store.fetchData();
        this.yearsOptions = this.store.years || [];
        this.DisapilityOptions = this.store.disabilities || [];
        this.seasonOptions = this.store.seasonStudies || [];
        this.curriculaOptions = this.store.Curricula || [];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async filterDisabilities() {
      if (!this.Courses.stage_ids || this.Courses.stage_ids.length === 0)
        return; // التأكد من وجود المراحل

      // استخراج المعرفات لكل مرحلة
      const selectedStageIds = this.Courses.stage_ids.map((stage) => stage.id);

      console.log(selectedStageIds, "selected stage ids");

      // استدعاء دالة fetchDisabilities مع IDs المراحل
      await this.store.fetchDisabilities(selectedStageIds);

      // تخزين الإعاقات التي تم جلبها
      this.filteredDisabilityOptions = this.store.disabilities || [];
    },
    async getStages() {
      if (!this.Courses.curriculum_id) return;
      try {
        await this.store.fetchStages(this.Courses.curriculum_id.id);
        this.stagesOptions = this.store.Stages || [];
      } catch (error) {
        console.error("Error fetching stages:", error);
      }
    },

    // دالة تصفية الإعاقات بناءً على المراحل المختارة

    changeAllCurriculum() {
      // التبديل بين إظهار وإخفاء المراحل والإعاقات عند التغيير
      this.showStages = !this.Courses.all_curriculum;
      this.showDisability = !this.Courses.all_curriculum;

      // إذا تم إخفاء المراحل والإعاقات، نقوم بإعادة تعيين قيمتها
      if (!this.showStages) this.Courses.stage_ids = [];
      if (!this.showDisability) this.Courses.disability_ids = [];
    },

    submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const payload = {
          ...this.Courses,
          year_id: this.Courses.year_id.id,
          season_id: this.Courses.season_id.id,
          curriculum_id: this.Courses.curriculum_id?.id || null,
          disability_ids: this.Courses.disability_ids.map((item) => item.id),
          stage_ids: this.showStages
            ? this.Courses.stage_ids.map((item) => item.id)
            : [],
          all_curriculum: this.showStages ? 0 : 1,
        };
        this.store.addCourse(payload);
      }
    },
  },
  watch: {
    "Courses.curriculum_id": "getStages",
    "Courses.stage_ids": "filterDisabilities",
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.all-btn {
  display: flex;
  gap: 10px;
  .save {
    width: 20%;
  }
  .multiselect__tags {
    margin-bottom: 1rem;
  }
}
</style>
