<template>
  <div class="add-lesson">
    <div class="head">
      <h4 style="font-family: 'bold'">عرض طلاب المجموعه</h4>
    </div>

    <!-- الجدول -->
    <div class="table-responsive">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          v-model="searchQuery"
          @input="debounceSearch"
          placeholder="ابحث عن اسم الطالب"
        />
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>اسم الطالب</th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="students.length === 0">
            <td colspan="12" style="text-align: center !important">
              لا يوجد طلاب
            </td>
          </tr>
          <tr v-for="student in students" :key="student.id">
            <td>{{ student.id }}</td>
            <td>{{ student.name }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="pagination.last_page > 1">
        <button
          class="prev"
          :disabled="pagination.current_page <= 1"
          @click="getStudents(pagination.current_page - 1)"
        >
          &laquo; السابق
        </button>

        <button
          v-for="page in pagination.last_page"
          :key="page"
          :class="{ active: pagination.current_page === page }"
          @click="getStudents(page)"
        >
          {{ page }}
        </button>

        <button
          class="next"
          :disabled="pagination.current_page >= pagination.last_page"
          @click="getStudents(pagination.current_page + 1)"
        >
          التالي &raquo;
        </button>
      </div>
    </div>
  </div>
</template>

  
<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { debounce } from "lodash";

export default {
  name: "AddLessonGroup",
  data() {
    return {
      searchQuery: "",
      pagination: {},
      links: {},

      students: [],
      debounceSearch: null, // تعريف debounceSearch
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    async searchLessons() {
      try {
        const res = await axios.post("organization_fetch_group_students", {
          group_id: this.$route.params.groupId,
          course_id: this.$route.params.courseId,
          word: this.searchQuery,
        });
        if (res.data.status === true) {
          this.students = res.data.data.data;
        } else {
          this.students = []; // تفريغ الجدول عند عدم وجود نتائج
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء الاتصال بالخادم.";
        Swal.fire({
          icon: "error",
          title: "فشل البحث",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
        });
        this.students = []; // تفريغ الجدول عند وجود خطأ
      }
    },

    async getStudents(page = 1) {
      try {
        const res = await axios.post("organization_fetch_group_students", {
          group_id: this.$route.params.groupId,
          course_id: this.$route.params.courseId,
          page: page,
        });

        if (res.data.status) {
          this.students = res.data.data.data;
          this.pagination = res.data.data.meta;
          this.links = res.data.data.links;
        } else {
          console.warn("Failed to fetch students, status:", res.data.status);
        }
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    },
  },
  mounted() {
    this.getStudents();

    // تعريف debounceSearch باستخدام debounce
    this.debounceSearch = debounce(this.searchLessons, 700);
  },
};
</script>

  <style lang="scss" scoped>
button.save {
  width: 20% !important;
}
.search {
  margin-top: 1rem;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--main);
      color: #fff;
    }

    &.active {
      background-color: var(--main);
      color: #fff;
      font-weight: bold;
    }

    &:disabled {
      background-color: #eee;
      color: #aaa;
      cursor: not-allowed;
      font-family: "regular";
    }

    &.prev,
    &.next {
      font-size: 16px;
      font-family: "regular";
    }

    &:not(.active) {
      background-color: #f5f5f5;
      color: #333;
    }
  }
  .add-lesson {
    padding: 2rem;
  }
  .all-btn {
    margin-top: 2rem;
  }
  .head h4 {
    font-family: "bold";
  }
  button.save {
    display: block;
    margin-inline-start: auto;
    background-color: var(--main);
    color: var(--secondary);
    font-family: "regular";
    border: 0;
    padding: 0.8rem 2rem;
    border-radius: 10px;
    transition: 0.7s;
    width: 25%;
    border: 1px solid var(--main);
  }
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
i.fa-solid.fa-trash {
  color: red;
}
</style>