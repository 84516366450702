<template>
  <div class="adress">
    <h4>عرض تفاصيل الحصه</h4>
  </div>
  <div class="lesson-view">
    <div class="lesson-show">
      <h4><span>الحصه :</span> {{ details.title }}</h4>
      <h4><span>المنهج :</span> {{ details.curriculum_title }}</h4>
      <h4><span>المرحله :</span> {{ details.stage_title }}</h4>
    </div>
  </div>
  <div class="lesson-view">
    <div class="lesson-show">
      <h4><span>الاعاقات :</span> {{ details.disability_types }}</h4>
    </div>
  </div>
  <div class="lesson-view">
    <div class="lesson-show">
      <h4><span>السوره :</span> {{ details.surah_title }}</h4>
    </div>
  </div>
  <div class="lesson-view">
    <div class="lesson-show">
      <h4><span>من الايه :</span>( {{ details.start_ayah }}) - ({{ details.start_ayah_title }})</h4>
      <h4><span>الي الايه :</span>({{ details.end_ayah }}) - ({{ details.end_ayah_title }}) </h4>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "lessonsView",
  data() {
    return {
      details: [],
    };
  },
  methods: {
    async getDetails() {
      try {
        const response = await axios.post(
          "organization_fetch_admin_sessions_detail",
          {
            session_id: this.$route.params.id,
          }
        );
        this.details = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>
<style scoped>
.adress {
  margin-bottom: 1.5rem;
  font-family: "bold";
  color: var(--main);
}
.lesson-view {
  background: #f7f7f7;
  margin-right: 1rem;
  margin-bottom: 2rem;
}
.lesson-show {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 80%;
  gap: 2rem;
}
.lesson-show h4 {
  font-family: "regular";
  color: black;

  font-size: 1.2rem;
}
.lesson-show span {
  color: var(--main);
  font-family: "regular";
  font-size: 1.2rem;
}
.adress {
  text-align: center;
}
</style>