<template>
  <div class="content">
    <div class="row">
      <!-- <div class="col-lg-7 col-md-12 col-12">
        <div class="employment_rates">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <h5>معدل التفاعل مع الموظفين</h5>
          </div>

          <div class="card">
            <Chart
              type="line"
              :data="chartDataRates"
              :options="chartOptionsRates"
              style="height: 400px"
              class="h-[30rem]"
            />
          </div>
        </div>
      </div> -->
      <div class="col-lg-12 col-md-12 col-12">
        <div class="team_shift">
          <div class="d-flex justify-content-between align-items-center">
            <h5>الطلاب المنضمين حديثا</h5>
            <router-link to="/students" class="btn">عرض الكل</router-link>
          </div>
          <!-- ======= table team ======= -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">صوره</th>
                  <th scope="col">الإسم</th>
                  <th scope="col">الهاتف</th>
                  <th scope="col">مستوي الاعاقة</th>
                  <th scope="col">المجموعة</th>
                  <th scope="col">تاريخ الانضمام</th>
                </tr>
              </thead>
              <tbody>
                <!-- For loop this details team -->
                <tr
                  v-for="student in this.store.latest_students"
                  :key="student.id"
                >
                  <td class="name">
                    <div class="details_team">
                      <div class="contact">
                        <img :src="student.image" alt="" />
                      </div>
                    </div>
                  </td>
                  <td data-label="المستوي الوظيفي">{{ student.name }}</td>
                  <td data-label="رقم الهاتف">{{ student.phone }}</td>
                  <td data-label="نوع العمل">{{ student.disability_type }}</td>
                  <td data-label="نوع العمل">{{ student?.group?.title }}</td>
                  <td data-label="نوع العمل">{{ student.created_at }}</td>
                </tr>
                <!-- For loop this details team -->
              </tbody>
            </table>
          </div>
          <!-- ======= table team ======= -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHomeStore } from "../../stores/HomeStore/HomeStore";
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  data() {
    return {
      store: useHomeStore(),
      chartDatapercentageProjectsDay: null,
      chartOptionspercentageProjectsDay: null,
      chartOptionsRates: null,
      chartDataRates: null,
    };
  },
  mounted() {
    this.store.fetchLateststudents();
    this.chartDatapercentageProjectsDay =
      this.setChartDatapercentageProjectsDay();
    this.chartDataRates = this.setChartDataRates();
    this.chartOptionsRates = this.setChartOptionsRates();
  },
  methods: {
    // =============Chart Employment Rates=============
    setChartDatapercentageProjectsDay() {
      return {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            tension: 0.4,
            borderColor: "white",
          },
          {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            borderDash: [5, 5],
            tension: 0.4,
            borderColor: "#996b92",
          },
          {
            label: "Third Dataset",
            data: [12, 51, 62, 33, 21, 62, 45],
            fill: true,
            borderColor: "#996b92",
            tension: 0.4,
            backgroundColor: "#e9f0ed",
          },
        ],
      };
    },
    setChartOptionspercentageProjectsDay() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },
    // ==========Chart Empoloyess Activeity================
    setChartDataRates() {
      return {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            tension: 0.4,
            borderColor: "white",
          },
          {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            borderDash: [5, 5],
            tension: 0.4,
            borderColor: "#996b92",
          },
          {
            label: "Third Dataset",
            data: [12, 51, 62, 33, 21, 62, 45],
            fill: true,
            borderColor: "#996b92",
            tension: 0.4,
            backgroundColor: "#e9f0ed",
          },
        ],
      };
    },
    setChartOptionsRates() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
table.table {
  & tbody {
    & tr {
      td {
        text-align: center !important;
        width: unset !important;
      }
    }
  }
}
.layout .main .content > div[data-v-6e3a9952] {
  padding: 1.5rem;
  border-radius: 0.6rem;
  box-shadow: #3f3f44 0 0 0 0;
  margin-top: -53px;
}
.contact {
  margin: auto;
}
</style>
