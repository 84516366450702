import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useCoursesAddStore = defineStore("coursesAdd", {
  state: () => ({
    courses: [],
    Curricula: [],
    curriculum_id: [],
    disabilities: [],
    Stages: [],
    stage_ids: [],
    seasonStudies: [],
    disability_ids: [],
    years: [],
    years_id: [],
  }),
  actions: {
    async fetchSeasons() {
      try {
        const response = await axios.get("organization_fetch_seasons");
        if (response.data.status == true) {
          this.seasonStudies = response.data.data;
        } else {
          console.error("Error fetching seasons:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchCurricula() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        if (response.data.status) {
          this.Curricula = response.data.data;
        } else {
          console.error("Error fetching curricula:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchStages(id) {
      try {
        const response = await axios.post("organization_fetch_stages", {
          curriculum_id: id,
        });
        if (response.data.status == true) {
          this.Stages = response.data.data.data;
          this.stage_ids = this.Stages.map((stage) => stage.id);
        } else {
          console.error("Error fetching stages:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async fetchDisabilities(id) {
      try {
        // إذا كانت المراحل موجودة في الموديل
        if (this.stage_ids.length === 0) {
          this.disabilities = []; // إذا لم يكن هناك مراحل، قم بتصفية الإعاقات
          return;
        }

        const response = await axios.post("organization_fetch_disability_by_stage_ids", {
          stage_ids: id,  // إرسال قائمة المراحل
        });

        if (response.data.status === true) {
          this.disabilities = response.data.data;
          this.disability_ids = this.disabilities.map((disability) => disability.id);
        } else {
          console.error("Error fetching disabilities:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async fetchYears() {
      try {
        const response = await axios.get("organization_fetch_years");
        if (response.data.status === true) {
          this.years = response.data.data;
          this.years_id = this.years;
        } else {
          console.error("Error fetching years:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchData() {
      try {
        await Promise.all([
          this.fetchSeasons(),
          this.fetchCurricula(),
          this.fetchDisabilities(),
          this.fetchYears(),
        ]);
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    },
    async addCourse(courseData) {
      try {
        const formData = new FormData();
        Object.keys(courseData).forEach((key) => {
          if (Array.isArray(courseData[key])) {
            courseData[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            if (key === "imageSrc") {
            } else if (
              key === "stage_ids" &&
              courseData.all_curriculum === 0
            ) {
            } else {
              formData.append(key, courseData[key]);
            }
          }
        });

        const response = await axios.post("add_course", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          this.courses.push(response.data.data);
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الدورة بنجاح",
          });
          router.push("/courses");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error while adding course:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response?.data?.message || "حدث خطأ أثناء الإضافة",
        });
      }
    },
  },
});
