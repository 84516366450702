<template>
  <div class="show-result">
    <div class="hed">
      <h4 style="font-family: 'bold'; margin-bottom: 1rem">عرض النتائج</h4>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>اسم الطالب</th>
            <th>النتيجه</th>
            <th>وقت الامتحان</th>
            <th>التفاصيل</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="show.length === 0" colspan="5" class="text-center">
              لا توجد بيانات لعرضها
            </td>
          </tr>
          <tr v-for="resualt in show" :key="resualt.id">
            <td>{{ resualt.user_name }}</td>
            <td>{{ resualt.degree }}</td>
            <td>{{ formatDate(resualt.created_at) }}</td>
            <td>
              <router-link
                :to="`/exams-show-student/${resualt.id}/${resualt.user_id}`"
              >
                <i class="fa-solid fa-eye"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination" v-if="pagination.last_page > 1">
      <button
        class="prev"
        :disabled="pagination.current_page <= 1"
        @click="fetchSessions(pagination.current_page - 1)"
      >
        &laquo; السابق
      </button>

      <button
        v-for="page in pagination.last_page"
        :key="page"
        :class="{ active: pagination.current_page === page }"
        @click="fetchSessions(page)"
      >
        {{ page }}
      </button>

      <button
        class="next"
        :disabled="pagination.current_page >= pagination.last_page"
        @click="fetchSessions(pagination.current_page + 1)"
      >
        التالي &raquo;
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      show: [],
      pagination: {},
      links: {},
    };
  },
  methods: {
    formatDate(datetime) {
      if (!datetime) return "";
      const [date, time] = datetime.split(" ");

      if (!time) return `📅 التاريخ: ${date}`;

      // تقسيم الوقت إلى ساعات ودقائق
      let [hours, minutes] = time.split(":");
      hours = parseInt(hours);

      // تحديد AM أو PM
      const period = hours >= 12 ? "مساءً" : "صباحًا";

      // تحويل إلى صيغة 12 ساعة
      hours = hours % 12 || 12;

      return `📅 التاريخ: ${date} ⏰ الوقت: ${hours}:${minutes} ${period}`;
    },

    async fetchShowResualtsStore(page = 1) {
      try {
        const response = await axios.post("fetch_group_exam_user", {
          exam_id: this.$route.params.id,
          page: page,
        });
        if (response.data.status === true) {
          this.show = response.data.data.data;
          this.pagination = res.data.data.meta;
          this.links = res.data.data.links;
          Swal.fire({
            icon: "success",
            title: "تم تحميل البيانات بنجاح",

            text: response.data?.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.fetchShowResualtsStore();
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--main);
      color: #fff;
    }

    &.active {
      background-color: var(--main);
      color: #fff;
      font-weight: bold;
    }

    &:disabled {
      background-color: #eee;
      color: #aaa;
      cursor: not-allowed;
      font-family: "regular";
    }

    &.prev,
    &.next {
      font-size: 16px;
      font-family: "regular";
    }

    &:not(.active) {
      background-color: #f5f5f5;
      color: #333;
    }
  }
}
</style>